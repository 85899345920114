import sanityClient from '@sanity/client'

/**
 *  Sanity Client - provides identifier for our Sanity data
 *  @version 1.0
 *  @author Nadia Mayangputri
 *  @see {@link https://www.sanity.io/docs/js-client} for more information
 */ 

export default sanityClient({
  projectId: 'kapewxn2',
  dataset: 'production', 
  useCdn: true // `false` if you want to ensure fresh data
})
import imageUrlBuilder from '@sanity/image-url';
import client from "../client"


/**
 * Generate image urls from Sanity image records.
 * @param {string} source 
 * @see {@link https://www.sanity.io/docs/image-url} for more information
 */
    export function urlFor(source) {
    return imageUrlBuilder(client).image(source)
    }